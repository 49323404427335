import Loader from "@/components/loader";
import {baseBlackboxUrl, blackboxApi} from "../../main";
import draggable from "vuedraggable";
import {mapGetters} from "vuex";
import modalConfirm from "@/components/modal-confirm";

import Vue from "vue";
import VTooltip from "v-tooltip";

Vue.use(VTooltip);

export default {
    name: "FileBrowser",
    props: ["title", "data", "readOnly", "case_id", "preCase", "entityId"],
    components: {
        "neo-loader": Loader,
        draggable,
        modalConfirm,
    },
    data() {
        return {
            list1: [
                {name: "John", id: 1},
                {name: "Joao", id: 2},
                {name: "Jean", id: 3},
                {name: "Gerard", id: 4},
            ],
            list2: [
                {name: "Juan", id: 5},
                {name: "Edgard", id: 6},
                {name: "Johnson", id: 7},
            ],
            directorySortMap: {},
            caseId: "",
            isRelative: false,
            fileRenameError: false,
            navigationStack: [],
            openDeleteObjectPopup: false,
            searchResults: [],
            searchKey: "",
            operationErrored: false,
            parent: "_ROOT",
            showRenamePopup: false,
            isContentSelected: false,
            // directoryContents:[],
            currentDirDepth: 0,
            showLoader: false,
            showDuplicateContentError: false,
            activeDirectoryContent: [],
            directoryStack: [],
            newFolderName: "",
            currentPath: [],
            openCreateFolderPopup: false,
            activeX: 0,
            activeY: 0,
            showList: false,
            newName: "",
            contextMenuUid: new Date().toLocaleString(),
            options: [{name: "Create folder"}, {name: "Add file"}],
            editOptions: [{name: "Rename"}, {name: "Delete"}],
            shiftPressed: true,
            // vueSimpleContextMenu:'dd',
            mode: "select",
            dirView: "grid",
            showSearchBox: false,
            hideInput: true,
            deletedObjectInputValue: "",
            showInvalidDeleteName: false,
            Cache: {},
            LoadingInBackground: false,
        };
    },
    computed: {
        ...mapGetters(["getReadOnlyMode", "checkIsUserManagedCase"]),
        getCaseId() {
            return this.$store.getters.getCaseId || this.caseId || this.case_id;
        },

        isManagedCase() {
            return !this.$store.getters.checkIsUserManagedCase ? true : false;
        },

        menuEditOptions() {
            return this.title !== "CLIENT UPLOADS" || !this.isManagedCase ? this.editOptions : this.editOptions.filter((el) => el.name !== "Delete");
        },

        getDocType() {
            return this.title.split(" ").join("__");
        },
        getObjectType() {
            var item = this.selectedItem;
            if (item.file_name || item.node_type === "file") {
                return "file";
            } else {
                return "directory";
            }
        },
        getViewIcon() {
            if (this.dirView === "grid") return require("@/assets/list-button.svg");
            else return require("@/assets/gridview.svg");
        },
        getViewName() {
            return this.dirView === "grid" ? "List View" : "Grid View";
        },
        getCurrentPath() {
            // var cp  = this.currentPath.slice(0,this.currentDirDepth)

            return this.navigationStack
                .filter((e, i) => {
                    return i <= this.currentDirDepth;
                })
                .map((e) => {
                    return e.name;
                });

            // var cp = ["demo","hello","user","mod","demo","hello","user","mod","demo","hello","user","mod","demo","hello","user","mod"]
            // return cp.splice(cp.length-4, cp.length)
            return cp;
        },
        getDirectoryContents() {
            return this.activeDirectoryContent;
        },
        contextMenuCondition() {
            return this.readOnly ? this.title !== "CLIENT OUTPUTS" : true;
        },
    },
    async created() {
        this.caseId = this.$route.params.id;
    },
    async mounted() {
        await this.fetchDirectoryContents();

        // // const containers = document.querySelectorAll('.dropzone');

        // let dropzones = document.querySelectorAll('.dropzone');

        // let droppable = new Droppable(
        //     dropzones,
        //     {
        //         draggable: '.draggable',
        //         dropzone:  '.dropzone',
        //         //   mirror:    { constrainDimensions: true }
        //     }
        //     );

        // // const containerTwoCapacity = 3;
        // // const containerTwoParent = sortable.containers[1].parentNode;
        // // let currentMediumChildren;
        // // let capacityReached;
        // // let lastOverContainer;

        // // --- Draggable events --- //
        // sortable.on('drag:start', (evt) => {
        // //   currentMediumChildren = sortable.getDraggableElementsForContainer(sortable.containers[1])
        // //     .length;
        // //   capacityReached = currentMediumChildren === containerTwoCapacity;
        // //   lastOverContainer = evt.sourceContainer;
        // //   containerTwoParent.classList.toggle(Classes.capacity, capacityReached);
        // });

        // sortable.on('sortable:sort', (evt) => {
        // //   if (!capacityReached) {
        // //     return;
        // //   }

        // //   const sourceIsCapacityContainer = evt.dragEvent.sourceContainer === sortable.containers[1];

        // //   if (!sourceIsCapacityContainer && evt.dragEvent.overContainer === sortable.containers[1]) {
        // //     evt.cancel();
        // //   }
        // });

        // sortable.on('sortable:sorted', (evt) => {
        // //   if (lastOverContainer === evt.dragEvent.overContainer) {
        // //     return;
        // //   }

        // //   lastOverContainer = evt.dragEvent.overContainer;
        // });

        // this.activeDirectoryContent = this.data;

        // const selection = new SelectionArea({
        //     // container:'.selectable',
        //     // document: window.document,
        //                 // Query selectors for elements from where a selection can be started from.
        //     startareas: ['.folder'],

        //     // Query selectors for elements which will be used as boundaries for the selection.
        //     boundaries: ['.folder'],
        //     // class: 'selection-area',
        //     container: '.folder',

        //     selectables:['.folder > .folder--icon--container'],
        //     // singleTap: {

        //     //     // Enable single-click selection (Also disables range-selection via shift + ctrl).
        //     //     allow: false,

        //     //     // 'native' (element was mouse-event target) or 'touch' (element visually touched).
        //     //     intersect: 'touch'
        //     // },

        // }).on('start',({store, event})=>{
        //         if (!event.ctrlKey && !event.metaKey) {
        //             this.shiftPressed = false;
        //             // Unselect all elements
        //             for (const el of store.stored) {
        //                 el.classList.remove('selected');
        //             }

        //             // Clear previous selection
        //             // selection.clearSelection();
        //         }

        //     // this.isContentSelected = true;
        //     // this.mode = 'drag'
        // }).on('move', (evt) => {

        //     // Add a custom class to the elements that where selected.
        //     var added = evt.store.changed.added;
        //     var removed = evt.store.changed.removed;

        //     for (const el of added) {
        //         el.classList.add('selected');
        //         // added.style.background ='red'
        //     }

        //     // Remove the class from elements that where removed
        //     // since the last selection

        //     // for (const el of removed) {
        //     //     el.classList.remove('selected');
        //     // }

        // }).on('stop', () => {
        //     selection.keepSelection();
        //     // if(selection.getSelection().length>0){
        //     //     this.isContentSelected = true;
        //     // }
        //     const selected = selection.getSelection();
        //     for(const el of selected){
        //         el.classList.add('selected')
        //     }
        // });
        // // document.addEventListener("dragstart", function( event ) {
        // //     // store a ref. on the dragged elem
        // //     var dragged = event.target;
        // //     // make it half transparent
        // //     event.target.style.opacity = .5;
        // // }, false);
    },

    methods: {
        add: function () {
            this.list.push({name: "Juan"});
        },
        replace: function () {
            this.list = [{name: "Edgard"}];
        },
        clone: function (el) {
            return {
                name: el.name + " cloned",
            };
        },
        log: function (evt) {},
        toggleDirViewIcon() {
            if (this.dirView === "grid") {
                this.dirView = "list";
                this.showList = true;
            } else {
                this.dirView = "grid";
                this.showList = false;
            }
        },
        clearOperationErroredState() {
            setTimeout(() => {
                this.operationErrored = false;
                // this.fileRenameError = false;
            }, 3000);
        },

        navigateToDirectory(path) {
            if (this.currentDirDepth > 0) {
                this.currentDirDepth = this.navigationStack.findIndex((stack) => stack.name === path);
                if (!this.currentDirDepth) this.currentDirDepth = 0;
                this.handleFolderClick(this.navigationStack[this.currentDirDepth], true);
                if (this.currentDirDepth === 0) {
                    this.currentPath = [];
                }
            }
        },

        handleClick1(event, item) {
            this.$refs.vueSimpleContextMenu1.showMenu(event, item);
            // document.getElementById("CLIENT UPLOADS1").style.position.top=`10px`
        },

        handleClickEditOptions(event, item) {
            //   this.hiderRenamePopup()
            this.$refs.vueSimpleContextMenu2.showMenu(event, item);
            //   var rect = event.target.getBoundingClientRect()
            this.recordXY(event.target.offsetLeft + 6, event.target.offsetTop);
        },

        recordXY(x, y) {
            this.activeX = x;
            this.activeY = y;
        },

        // hiderRenamePopup(){
        //     var popup = document.querySelector('.rename--popup--wrapper')
        //     popup.style.display='none';
        // },
        openFileOption() {
            document.getElementById(this.title).click();
        },
        async uploadFile(evt) {
            let confirm = false;
            if (this.title == "CLIENT OUTPUTS") {
                const confirm_modal = await this.$refs["modal_confirm_upload"].show({title: "Are you sure?", message: "Please confirm that you want to upload this file. Uploaded files will be visible to Client"});
                this.$refs["modal_confirm_upload"].close();
                confirm = confirm_modal;
            } else confirm = true;
            if (confirm) {
                var files = evt.target.files;
                var file = files[0];

                //set loader.
                var duplicate = this.activeDirectoryContent.findIndex((e) => {
                    return e.node_type === "file" && e.file_name === file.name;
                });

                if (duplicate != -1) {
                    this.showDuplicateContentError = true;
                    this.operationErrored = true;
                    this.clearOperationErroredState();
                    return;
                }

                this.isRelative = true;
                this.showLoader = true;
                console.log(this.parent, "this.parent");
                var caseId = this.getCaseId;
                let url = "";
                if (this.preCase) {
                    url = `/api/v1/docs/files?entity_id=${this.entityId}&folder_id=${this.parent}&doc_type=${this.getDocType}`;
                } else {
                    url = `/api/v1/docs/files?case_id=${caseId}&folder_id=${this.parent}&doc_type=${this.getDocType}`;
                }
                var formData = new FormData();
                formData.append("file", file);

                try {
                    const response = await blackboxApi.post(url, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });

                    this.activeDirectoryContent.push(response.data);
                } catch (error) {
                    this.operationErrored = true;
                    // this.operationErrored = true;
                    this.clearOperationErroredState();
                }
                this.isRelative = false;
                this.showLoader = false;
            } else return;
        },
        openFolderCreatePopup() {
            this.newFolderName = "";
            this.isRelative = true;
            this.openCreateFolderPopup = true;
        },

        closeFolderCreationPopup() {
            this.isRelative = false;
            this.openCreateFolderPopup = false;
        },

        closeDeleteObjectPopup() {
            this.isRelative = false;
            this.openDeleteObjectPopup = false;
        },

        handleNavigateBack() {
            if (this.currentDirDepth > 0) {
                this.currentDirDepth = this.currentDirDepth - 1;
                // this.activeDirectoryContent = this.directoryStack[this.currentDirDepth];
                this.handleFolderClick(this.navigationStack[this.currentDirDepth], true);
                if (this.currentDirDepth === 0) {
                    // this.navigationStack.push({_id:'_ROOT'});
                    this.currentPath = [];
                }
            }
        },

        handleNavigateForward() {
            if (this.currentDirDepth < this.navigationStack.length - 1) {
                this.currentDirDepth = this.currentDirDepth + 1;
                this.handleFolderClick(this.navigationStack[this.currentDirDepth], true);
            }
        },

        handleFolderBodyClick() {
            var popup = document.querySelector(".rename--popup--wrapper");
            popup.style.display = "none";
        },

        // searchInLocalCache(dirId){
        //     var cached = this.directoryStack.filter(dir=>{
        //         dir.filter(obj)
        //     })
        // },

        async downloadFile(fileObject) {
            var caseId = this.getCaseId;
            var fileId = fileObject._id;
            var url = `/api/v1/docs/signed-url?case_id=${caseId}&file_id=${fileId}`;

            try {
                const response = await blackboxApi.get(url);
                return response.data.url;
            } catch (error) {
                console.log(error);
            }
        },
        async rename() {
            this.showRenamePopup = false;
            var exists = this.getDirectoryContents.filter((e) => {
                if (e.node_type === "file") {
                    return e.file_name === this.newName;
                }
                if (e.node_type === "directory") {
                    return e.folder_name === this.newName;
                }
            });

            if (exists.length > 0) {
                this.operationErrored = true;
                this.fileRenameError = true;

                this.clearOperationErroredState();
                return;
            }

            var popup = document.querySelector(".rename--popup--wrapper");
            popup.style.display = "none";
            var item = this.selectedItem;

            var url = `/api/v1/docs/rename`;
            var objectType = null;
            var id = null;
            var caseId = this.getCaseId;

            if (item.file_name || item.node_type === "file") {
                objectType = "file";
                id = item._id;
            } else {
                objectType = "directory";
                id = item._id;
            }

            if (!objectType) {
                this.operationErrored = true;
                this.clearOperationErroredState();
                return;
            }

            try {
                let doc = {
                    object_type: objectType,
                    object_id: id,
                    new_object_name: this.newName,
                };
                if (this.preCase) {
                    doc.entity_id = this.entityId;
                } else {
                    doc.case_id = caseId;
                }
                const response = await blackboxApi.post(url, doc);

                if (response && response.data && response.data.success) {
                    if (this.getObjectType === "file") {
                        this.selectedItem.file_name = this.newName;
                        this.$toast.success("File renamed successfully !");
                    }
                    if (this.getObjectType === "directory") {
                        this.selectedItem.folder_name = this.newName;
                        this.$toast.success("Folder renamed successfully !");
                    }
                }
            } catch (error) {
                if (error?.response?.data.detail[0]) {
                    this.$toast.error(error.response.data.detail[0].msg);
                } else {
                    this.$toast.error("Some error occurred ! Please try again later");
                }
            }
        },
        async searchDirectoryContent() {
            clearInterval(this.interval);
            this.interval = setTimeout(async () => {
                var caseId = this.getCaseId;
                let url = "";
                if (this.preCase) {
                    url = `/api/v1/docs/search?entity_id=${this.entityId}&doc_type=${this.getDocType}&search_key=${this.searchKey}`;
                } else {
                    url = `/api/v1/docs/search?case_id=${caseId}&doc_type=${this.getDocType}&search_key=${this.searchKey}`;
                }
                if (!this.searchKey) {
                    if (this.savedCurrentDirectoryContent) {
                        this.activeDirectoryContent = this.savedCurrentDirectoryContent;
                    }
                    return;
                }

                this.isRelative = true;
                // this.showLoader = true;
                try {
                    const response = await blackboxApi.get(url);
                    this.activeDirectoryContent = response.data.matches;
                } catch (error) {}
                this.isRelative = false;
                this.showLoader = false;
            }, 1000);
        },
        async fetchDirectoryContents(navigationMode) {
            //If there is a cached directory content use it
            //Make request and update content later on.

            var caseId = this.getCaseId;
            var currentDirectory = this.parent;
            // if(currentDirectory === '_ROOT')
            //     this.navigationStack.push({_id:currentDirectory})
            var doc_type = this.getDocType;

            let url = "";

            if (this.preCase) {
                url = `/api/v1/docs/directory-contents?entity_id=${this.entityId}&directory_id=${currentDirectory}&doc_type=${doc_type}`;
            } else {
                url = `/api/v1/docs/directory-contents?case_id=${caseId}&directory_id=${currentDirectory}&doc_type=${doc_type}`;
            }

            let config = {
                headers: {
                    "X-CASE-ID": caseId,
                },
            };

            if (this.Cache[url]) {
                this.activeDirectoryContent = this.Cache[url];
                this.savedCurrentDirectoryContent = this.Cache[url];
                this.LoadingInBackground = true;
            } else {
                this.isRelative = true;
                this.showLoader = true;
            }

            try {
                const response = await blackboxApi.get(url, config);
                this.activeDirectoryContent = response.data.directory_contents;
                this.savedCurrentDirectoryContent = response.data.directory_contents;
                this.Cache[url] = response.data.directory_contents;
                this.LoadingInBackground = false;
            } catch (error) {
                this.operationErrored = true;
                this.clearOperationErroredState();
            }
            // var dirContents = [];

            this.isRelative = false;
            this.showLoader = false;
        },

        async createFolder() {
            var duplicate = this.activeDirectoryContent.findIndex((e) => {
                return e.node_type === "directory" && e.folder_name === this.newFolderName;
            });

            if (duplicate != -1) {
                this.showDuplicateContentError = true;
                this.operationErrored = true;
                this.clearOperationErroredState();
                return;
            }

            if (this.newFolderName.trim() === "") {
                this.operationErrored = true;
                this.clearOperationErroredState();
                return;
                // this.invalidFolderName
            }
            //start loader
            this.isRelative = true;
            this.showLoader = true;

            var folderType = this.title.split(" ").join("__");
            try {
                let doc = {
                    folder_name: this.newFolderName,
                    // case_id: this.getCaseId,
                    folder_type: folderType,
                    parent_id: this.parent,
                };
                if (this.preCase) {
                    doc.entity_id = this.entityId;
                } else {
                    doc.case_id = this.getCaseId;
                }
                let response = await blackboxApi.post("/api/v1/docs/folders", doc);
                if (response && response.data && response.data.folder_id) {
                    this.$toast.success("Folder created successfully !");
                }

                this.activeDirectoryContent.push({folder_name: this.newFolderName, node_type: "directory", _id: response.data.folder_id});
            } catch (error) {
                if (error?.response?.data.detail[0]) {
                    this.$toast.error(error.response.data.detail[0].msg);
                } else {
                    this.$toast.error("Some error occurred ! Please try again later");
                }
            }
            this.openCreateFolderPopup = false;
            this.isRelative = false;
            this.showLoader = false;

            this.newFolderName = "";
        },
        sortAlphabetically() {
            if (this.directorySortMap[this.parent] !== "undefined") {
                this.activeDirectoryContent = this.activeDirectoryContent.slice().reverse();
            } else {
                this.activeDirectoryContent = this.activeDirectoryContent.sort((a, b) => {
                    var aname = a.folder_name || a.file_name;
                    var bname = b.folder_name || b.file_name;
                    return (aname.toLowerCase() > bname.toLowerCase()) - (bname.toLowerCase() < aname.toLowerCase());
                });
                this.directorySortMap[this.parent] = 1;
            }
        },

        async handleFolderClick(object, navigationMode) {
            // if(this.currentDirDepth === 0 && !navigationMode)
            //     this.navigationStack = []
            // const objectClickedType = this.getObjectType;
            if (object.node_type === "file") {
                var url = await this.downloadFile(object);
                var downloadableLink = `/images/${url}`;
                window.open(url, "_blank");
                return;
            }

            if (!navigationMode) {
                var currentDirectory = this.parent;
                if (currentDirectory === "_ROOT") this.navigationStack.push({_id: currentDirectory, name: object?.meta?.doc_type});

                if (this.currentDirDepth === 0 && this.navigationStack[0]._id !== object._id) {
                    this.navigationStack = this.navigationStack.splice(0, 1);
                }
            }
            if (!navigationMode) this.navigationStack.push({_id: object._id, name: object.folder_name});
            //  push current directory contents in an array for ref later on back.
            this.parent = object._id;
            var lastDirContent = this.activeDirectoryContent;
            // this.directoryStack.push(lastDirContent);

            // this.handleNavigateForward()
            await this.fetchDirectoryContents();

            this.currentPath.push(object);
            if (!navigationMode) this.currentDirDepth = this.currentDirDepth + 1;
        },

        optionsClickedAddNewContent(event) {
            if (event.option.name === "Create folder") {
                this.openFolderCreatePopup();
            }
            if (event.option.name === "Add file") {
                this.openFileOption();
            }
        },

        clearErrorMessages() {
            setTimeout(() => {
                this.showInvalidDeleteName = false;
            }, 2000);
        },

        async deleteObject() {
            if (this.deletedObjectInputValue.trim() === "") {
                this.operationErrored = true;
                this.showInvalidDeleteName = true;
                this.clearErrorMessages();
                this.clearOperationErroredState();
                return;
            }

            var objectName = null;
            var objectType = this.getObjectType;
            if (objectType === "directory") {
                objectName = this.selectedItem.folder_name;
            }
            if (objectType === "file") {
                objectName = this.selectedItem.file_name;
            }

            if (this.deletedObjectInputValue != objectName) {
                this.operationErrored = true;
                this.clearOperationErroredState();
                this.showInvalidDeleteName = true;
                this.clearErrorMessages();
                return;
            }
            if (objectName && this.deletedObjectInputValue === objectName) {
                this.isRelative = true;
                this.showLoader = true;
                var objectType = this.getObjectType;
                var id = this.selectedItem._id;
                var caseId = this.getCaseId;

                this.activeDirectoryContent = this.getDirectoryContents.filter((e) => {
                    return e._id !== id;
                });

                // if(this.getObjectType === 'file'){
                //     objectType = 'file'
                //     id=item._id

                // }else{
                //     objectType = 'directory'
                //     id=item._id
                // }

                // if(!objectType){
                //     this.operationErrored = true;
                //     this.clearOperationErroredState();
                //     return;
                // }
                let url = "";
                if (this.preCase) {
                    url = `/api/v1/docs?entity_id=${this.entityId}&object_type=${objectType}&object_id=${id}`;
                } else {
                    url = `/api/v1/docs?case_id=${caseId}&object_type=${objectType}&object_id=${id}`;
                }
                try {
                    const response = await blackboxApi.delete(url);
                    this.isRelative = false;
                    this.showLoader = false;
                    this.openDeleteObjectPopup = false;
                } catch (error) {
                    this.operationErrored = true;
                    this.clearOperationErroredState();
                    this.isRelative = false;
                    this.showLoader = false;
                }
            }
        },

        async optionClickedEditActions(event) {
            this.selectedItem = event.item;

            if (event.option.name === "Delete") {
                this.isRelative = true;
                this.openDeleteObjectPopup = true;
                return;
            }

            if (event.option.name === "Rename") {
                // await this.rename(event.item)
            }
            // alert("dd")
            this.newName = event.item.name;
            // var x = event.target.offsetLeft
            // var y = event.target.offsetTop
            this.showRenamePopup = true;
            var popup = document.querySelector(".rename--popup--wrapper");
            popup.style.top = `${this.activeY + 60}px`;
            popup.style.left = `${this.activeX - 118}px`;
            popup.style.display = "inline";
        },
    },
};
