import FileBrowser from "@/components/filebrowser";
import {mapGetters, mapActions, mapMutations} from "vuex";
import {FILE_MANAGER_FOLDERS} from "@/utils/constants";

const ErrorMessage = () => import("@/components/server-error-message");

export default {
    name: "docBrowser",
    components: {
        FileBrowser,
        ErrorMessage,
    },
    computed: {
        ...mapGetters(["getReadOnlyMode", "getPermissions", "checkPermissionsFetched", "checkIsUserManagedCase", "getCaseId", "getValidCase"]),
        isAuth() {
            return this.getPermissions.includes("file_manager__case_data");
        },

        isManagedCase() {
            return !this.$store.getters.checkIsUserManagedCase ? true : false;
        },
    },
    props: {
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            emptyData: [],
            folders: FILE_MANAGER_FOLDERS,
        };
    },
    methods: {
        ...mapActions(["fetchPermissions"]),
        ...mapMutations(["SET_READ_ONLY"]),
    },
    mounted() {
        if (this.$route.name === "ReadOnlyDocBrowser") this.SET_READ_ONLY(true);
    },
    async created() {},
};
